import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  title = 'Semscoop';
  loading:boolean = true;
  constructor(){
    setTimeout(() => {
      this.loading = false;
    }, 1);
  }
}
