import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then(
        (module) => module.LandingPageModule
      ),
  },
  {
    path: 'keyword-tool',
    loadChildren: () =>
      import('./modules/full-keyword/full-keyword.module').then(
        (module) => module.FullKeywordModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((e) => e.LoginModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./modules/login/login.module').then((e) => e.LoginModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then(
        (e) => e.ProfileModule
      ),
  },
  {
    path: 'email/verify/:hash',
    loadChildren: () =>
      import('./modules/verify/verify.module').then((e) => e.VerifyModule),
  },
  {
    path: 'password/reset',
    loadChildren: () =>
      import('./modules/reset-password/reset-password.module').then(
        (e) => e.ResetPasswordModule
      ),
  },
  {
    path: 'password/reset/:token',
    loadChildren: () =>
      import(
        './modules/reset-password-form-body/reset-password-form-body.module'
      ).then((e) => e.ResetPasswordFormBodyModule),
  },
  {
    path: 'activate',
    loadChildren: () =>
      import('./modules/account-activate/account-activate.module').then(
        (e) => e.AccountActivateModule
      ),
  },
  {
    path: 'activate/:hash',
    loadChildren: () =>
      import(
        './modules/account-activate-proccess/account-activate-proccess.module'
      ).then((e) => e.AccountActivateProcessModule),
  },
  {
    path: 'subscribe/:token',
    loadChildren: () =>
      import('./modules/subscribe/subscribe.module').then(
        (e) => e.SubscribeModule
      ),
  },
  {
    path: 'subscribe/paypal/:status',
    loadChildren: () =>
      import('./modules/subscribe-paypal/subscribe-paypal.module').then(
        (e) => e.SubscribePaypalModule
      ),
  },
  {
    path: 'affiliates',
    loadChildren: () =>
      import('./modules/affiliates/affiliates.module').then(
        (e) => e.AffiliateModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./modules/contact/contact.module').then(
        (e) => e.ContactModule
      ),
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('./modules/membership/membership.module').then(
        (e) => e.MembershipModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'disabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
